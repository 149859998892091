import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {I18nService} from "@core/services/i18n/i18n.service";
import {NavigationStart, Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  constructor(private titleService: Title,
              private router: Router,
              private i18nService: I18nService) {
    this.i18nService.onLangChange().subscribe(() => {
      this.setTitle(router.url);
    });
    this.setPageTitle()
  }

  //Visible for testing
  setTitle(res: string) {
    let title: string = '';
    if (res === '/') {
      title = 'ROUTER.home';
    } else {
      title = this.convertUrl(res)
    }
    this.titleService.setTitle(title);
  }

  getTitleFromRoute(): string {
    return this.convertUrl(this.router.url);
  }

  private setPageTitle() {
    this.router.events.subscribe((res) => {
      if (res instanceof NavigationStart) {
        this.setTitle(res.url);
      }
    })
  }

  private convertUrl(url: string) {
    return url.split('/')
      .filter(Boolean)
      .map((segment: string) => this.i18nService.translatePhrase('ROUTER.' + (segment.toLowerCase())))
      .join(' | ');
  }
}
